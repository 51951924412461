<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    bottom
    left
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="c-input-xs input-filter"
        :class="{ 'c-input-has-sort': hasSort }"
        v-model="dateFormatted"
        :label="label"
        :placeholder="placeholder"
        :prepend-inner-icon="sortPrependIcon"
        @click:prepend-inner="sortClick"
        :clearable="clearable"
        hide-details
        outlined
        dense
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="clearDates"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="dates"
      range
      no-title
      scrollable
      :locale="$i18n.locale"
    >
      <div class="d-block w-100">
        <div
          class="error--text text-center font-italic fs-14px"
          v-if="maxRange && maxRange > 0"
        >
          {{ $t("labels.select_max_day", { day: maxRange }) }}
        </div>
        <div class="text-right my-2">
          <v-btn
            small
            color="primary"
            :outlined="isThisWeek()"
            :text="!isThisWeek()"
            @click="setThisWeek"
          >
            {{ $t("labels.this_week") }}
          </v-btn>
          <v-btn
            class="mx-1"
            small
            color="primary"
            :outlined="isThisMonth()"
            :text="!isThisMonth()"
            @click="setThisMonth"
          >
            {{ $t("labels.this_month") }}
          </v-btn>
          <v-btn
            small
            color="primary"
            :outlined="isLastMonth()"
            :text="!isLastMonth()"
            @click="setLastMonth"
          >
            {{ $t("labels.last_month") }}
          </v-btn>
        </div>

        <div class="text-right">
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="menu = false">Cancel</v-btn>
          <v-btn text color="success" @click="saveDate">OK</v-btn>
        </div>
      </div>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDateTime, debounce } from "@/libs/helpers";
import moment from "moment";

export default {
  name: "DateRangeFilter",
  props: {
    hasSort: {
      type: Boolean,
      default: () => false,
    },
    name: {
      type: String,
      default: () => "",
    },
    sortName: {
      type: String,
      default: () => "",
    },
    sorting: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    defaultDate: {
      type: [Array, String],
      default: () => null,
    },
    allowNull: {
      type: Boolean,
      default: () => true,
    },
    clearable: {
      type: Boolean,
      default: () => true,
    },
    maxRange: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    value: null,
    sortValue: null,
    dates: null,
    menu: null,
    dateFormatted: null,
  }),
  computed: {
    sortPrependIcon() {
      if (!this.hasSort) {
        return "";
      }
      switch (this.sortValue) {
        case "asc":
          return "mdi-arrow-up";
        case "desc":
          return "mdi-arrow-down";
        default:
          return "mdi-arrow-up-down";
      }
    },
  },
  watch: {
    dates() {
      this.dateFormatted =
        this.dates && this.dates[0] && this.dates[1]
          ? this.formatDateTime(this.dates[0], "DD/MM/YYYY") +
            " - " +
            this.formatDateTime(this.dates[1], "DD/MM/YYYY")
          : null;
    },
    sorting(val) {
      if (val !== this.sortName) {
        this.sortValue = null;
      }
    },
  },
  methods: {
    formatDateTime,
    isThisWeek() {
      const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
      const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
      return (
        this.dates &&
        this.dates[0] === startOfWeek &&
        this.dates[1] === endOfWeek
      );
    },
    isThisMonth() {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      return (
        this.dates &&
        this.dates[0] === startOfMonth &&
        this.dates[1] === endOfMonth
      );
    },
    isLastMonth() {
      const startOfLastMonth = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfLastMonth = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      return (
        this.dates &&
        this.dates[0] === startOfLastMonth &&
        this.dates[1] === endOfLastMonth
      );
    },
    setThisWeek() {
      const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
      const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
      this.dates = [startOfWeek, endOfWeek];
      this.saveDate();
    },
    setThisMonth() {
      const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      this.dates = [startOfMonth, endOfMonth];
      this.saveDate();
    },
    setLastMonth() {
      const startOfLastMonth = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfLastMonth = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      this.dates = [startOfLastMonth, endOfLastMonth];
      this.saveDate();
    },
    onFilter: debounce(function () {
      const dates = this.dates ? [...this.dates] : null;
      if (dates && dates[0] && dates[1]) {
        const date0 = dates[0];
        const date1 = dates[1];
        if (date0 > date1) {
          dates[0] = date1;
          dates[1] = date0;
        }
      }
      this.$emit("onFilter", {
        name: this.name,
        value: dates,
      });
    }, 500),
    saveDate() {
      if (this.dates && this.dates[0] && this.dates[1]) {
        const startDate = moment(this.dates[0]);
        const endDate = moment(this.dates[1]);
        const duration = endDate.diff(startDate, "days");

        if (this.maxRange && duration > this.maxRange) {
          this.$vToastify.error(
            this.$t("labels.select_max_day", { day: this.maxRange })
          );
          return false;
        }
      }

      this.$refs.menu.save(this.dates);
      this.onFilter();
    },
    clearDates() {
      if (!this.allowNull) {
        const dates = [
          moment().subtract("3", "months").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
        this.dates = [...dates];
      } else {
        this.dates = null;
      }
      this.saveDate();
    },
    sortClick() {
      if (!this.sortValue) {
        this.sortValue = "asc";
      } else if (this.sortValue === "asc") {
        this.sortValue = "desc";
      } else if (this.sortValue === "desc") {
        this.sortValue = "asc";
      }
      this.$emit("onSort", {
        sort_by: this.sortName,
        sort_type: this.sortValue,
      });
    },
  },
  mounted() {
    if (this.defaultDate && this.defaultDate.length > 0) {
      this.dates = [...this.defaultDate];
      this.saveDate();
    }
  },
};
</script>
